import React, { useState, useEffect, useRef } from "react"

import FeaturedWorkItems from "./FeatureWorkItems"

const FeaturedWork = ({ home, count, featuredWork, lightMode }) => {
  const projectsContainer = useRef(null)
  const [singleProjectHeight, setSingleProjectHeight] = useState(null)
  const [windowWidth, setWindowWidth] = useState(null)

  // Update state with Window width
  useEffect(() => {
    if (!projectsContainer?.current) return

    // Listen for window resize
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth)
    })

    return (
      // Remove window event listener on component unmount
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth)
      })
    )
  }, [])

  // Make blocks the same height
  useEffect(() => {
    if (projectsContainer?.current) {
      setSingleProjectHeight(0)

      // Hack: wait for the DOM to update
      setTimeout(() => {
        // Get the tallest project item and update all heights declaratively
        const projectsElBody = projectsContainer.current.querySelectorAll(
          ".card__body"
        )
        const projectHeights = Array.from(projectsElBody).map(item => {
          return item.clientHeight
        })
        const tallestProjectHeight = Math.max(...projectHeights)
        setSingleProjectHeight(tallestProjectHeight)
      }, 0)
    }
  }, [windowWidth])

  return (
    <section
      ref={projectsContainer}
      className={
        home
          ? "section section--tight-top"
          : `section ${lightMode ? `section--black` : ``}`
      }
    >
      <div className="container-fluid">
        <div className="row">
          <FeaturedWorkItems
            count={count}
            featuredWork={featuredWork}
            height={singleProjectHeight}
            lightMode={lightMode}
          />
        </div>
      </div>
    </section>
  )
}

export default FeaturedWork
