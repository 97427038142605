import React from "react"

import ProjectItem from "../ProjectItem"
import Reveal from "../Waypoints"

const FeaturedWorkItems = ({ count, featuredWork, lightMode, height }) => {
  const limit = count ? count : 3

  return featuredWork?.featuredWorkItems?.slice(0, limit).map((item, i) => {
    return (
      <div
        key={i}
        className={`col-bp1-12 ${
          limit === 3 ? "col-bp2-4" : "col-bp2-6 col-bp4-4"
        }`}
      >
        <Reveal delay={i / 4 + 0.5}>
          <ProjectItem
            index={i}
            slug={item.featuredWorkItem.slug}
            projectLogo={
              lightMode
                ? item.featuredWorkItem.projectFields.projectLogoDarkMode
                : item.featuredWorkItem.projectFields.projectLogoLightMode
            }
            previewImage={
              item.featuredWorkItem.projectFields.projectPreviewImage
            }
            shortDesc={item.featuredWorkItem.projectFields.projectShortDesc}
            totalProjects={3}
            totalCols={3}
            featured
            services={item.featuredWorkItem.projectFields.projectSubnav}
            lightMode={lightMode}
            height={height}
          />
        </Reveal>
      </div>
    )
  })
}

export default FeaturedWorkItems
