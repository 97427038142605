import React, { useEffect, useRef, useState } from "react"
import Img from "gatsby-image"

const Gallery = ({ images }) => {
  const galleryRef = useRef(null)
  const [scrollPosition, setScrollPosition] = useState(0)

  if (!images) return null

  const handleScroll = () => {
    setScrollPosition(window.scrollY)
  }

  useEffect(() => {
    if (!galleryRef.current) return

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [galleryRef])

  return (
    <ul className="gallery" ref={galleryRef}>
      {images.map(({ carouselImage }, i) => {
        if (!carouselImage?.localFile?.childImageSharp?.fluid) return null

        return (
          <li
            key={`${carouselImage.localFile.sourceUrl}-${i}`}
            className="gallery__item"
            style={{
              transform: `translateX(-${scrollPosition / 2}px)`,
            }}
          >
            <Img
              className="gallery__image"
              fluid={carouselImage.localFile.childImageSharp.fluid}
              alt={carouselImage.localFile.altText}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default Gallery
