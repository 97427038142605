import React, { useState } from "react"
import Img from "gatsby-image"
import { connect } from "react-redux"
import { createLocalLink } from "../../utils/createLocalLink"

import Chevron from "../Symbols/chevron"
import PageLink from "../PageLink"

const ProjectItem = props => {
  const [cardHover, toggleCardHover] = useState(false)
  const {
    index,
    slug,
    projectLogo,
    previewImage,
    shortDesc,
    totalProjects,
    lightMode,
    childLink,
    featured,
    services,
    height,
  } = props

  const handleCardHover = () => {
    toggleCardHover(!cardHover)
  }

  return (
    <div
      className={`card card--project
        ${lightMode ? ` card--light-mode` : ""}
        ${cardHover ? ` hover` : ""}
        ${featured ? ` card--featured card--featured${index + 1}` : ``}
        `}
      style={{ marginBottom: totalProjects === index + 1 ? "0" : null }}
    >
      {projectLogo?.sourceUrl && (
        <header className="card__header">
          <PageLink
            url={createLocalLink(`/work/${slug}`)}
            dispatch={props.dispatch}
            colour="white"
            childLink={childLink}
            menuLink={false}
            className="card__header-image"
          >
            <img src={projectLogo.sourceUrl} alt={projectLogo.altText} />
          </PageLink>
        </header>
      )}

      <div
        className="card__body card__body--project"
        style={{
          minHeight: height ? `${height}px` : `0`,
        }}
      >
        <PageLink
          url={createLocalLink(`/work/${slug}`)}
          dispatch={props.dispatch}
          colour="white"
          childLink={childLink}
          menuLink={false}
        >
          {previewImage?.localFile && (
            <Img
              className="card__image"
              fluid={previewImage.localFile.childImageSharp?.fluid}
              alt={previewImage.localFile?.altText ?? ""}
            />
          )}
        </PageLink>

        <div className="card__desc card__desc--project">
          <p dangerouslySetInnerHTML={{ __html: shortDesc }}></p>
        </div>

        <div
          className="card__underlay"
          style={{
            transform: cardHover ? `translateY(0%)` : `translateY(105%)`,
          }}
        >
          <ul>
            {services
              ? services.map((service, i) => {
                  return (
                    <li
                      key={i}
                      dangerouslySetInnerHTML={{
                        __html: service.projectSubnavItem.title,
                      }}
                    ></li>
                  )
                })
              : null}
          </ul>
        </div>
      </div>
      <footer
        className={`card__footer ${
          featured ? `card__footer--pure-white` : `card__footer--white`
        }`}
        data-cursor="alt"
        onMouseOver={() => handleCardHover()}
        onMouseOut={() => handleCardHover()}
      >
        <PageLink
          url={createLocalLink(`/work/${slug}`)}
          dispatch={props.dispatch}
          colour="white"
          cursor="alt"
          childLink={childLink}
          menuLink={false}
        >
          View Client <Chevron />
        </PageLink>
      </footer>
    </div>
  )
}

export default connect(
  state => ({
    background: state.background.background,
  }),
  null
)(ProjectItem)
