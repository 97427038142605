import React, { useEffect } from "react"
import { connect } from "react-redux"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { toggleBackground } from "../state/background"
import { setBreadcrumbs } from "../state/breadcrumbs"
import { toggleStickyHeaderColour } from "../state/header"

import SiteLayout from "../components/SiteLayout"
import Seo from "../components/Seo"
import Hero from "../components/Hero"
import FeaturedWork from "../components/FeaturedWork"
import ServiceSubNav from "../components/Services/ServiceSubNav"
import Title from "../components/Title"
import Gallery from "../components/Gallery"
import ImageGroup from "../components/ImageGroup"
import Reveal from "../components/Waypoints"

import HeroAnimation from "../components/Hero/HeroAnimation"
import animCreative from "../components/Hero/animations/creative.json"
import animComms from "../components/Hero/animations/communications.json"

import HeroImageStatic from "../components/Hero/HeroImageStatic"
import imgStaticCreative from "../components/Hero/images/Creative-Mob-Screen-1125px-01.svg"
import imgStaticComms from "../components/Hero/images/Communications-Mob-Screen-1125px-01.svg"

const Solutions = props => {
  const {
    transitionStatus,
    location,
    dispatch,
    pageContext,
    data: { allWpPage },
  } = props

  const {
    background,
    title,
    slug,
    seo,
    hero,
    introduction,
    solutionsContent,
    featuredWork,
    imageGroup,
    imageCarousel,
  } = allWpPage.edges[0].node

  useEffect(() => {
    // change bg and sticky header colour instantly,
    dispatch(toggleBackground(background.backgroundColour))
    setTimeout(() => {
      dispatch(toggleStickyHeaderColour(background.backgroundColour))
    }, 1000)
  }, [])

  useEffect(() => {
    // update breadcrumbs state
    setTimeout(() => {
      dispatch(
        setBreadcrumbs({
          current: {
            title: title,
            path: slug,
          },
        })
      )
    }, 1000)
  }, [])

  return (
    <SiteLayout location={location} transitionStatus={transitionStatus}>
      <Seo
        seoTitle={seo.title ? seo.title : title}
        seoDesc={seo.metaDesc ? seo.metaDesc : null}
        ogTitle={seo.opengraphTitle ? seo.opengraphTitle : seo.title}
        ogDesc={
          seo.opengraphDescription ? seo.opengraphDescription : seo.metaDesc
        }
        ogImage={
          seo.opengraphImage && seo.opengraphImage.localFile
            ? seo.opengraphImage.localFile.url
            : null
        }
      />

      <Hero
        slogan={hero.heroSlogan}
        heroImage={
          <HeroAnimation
            creative={slug === "creative" ? true : null}
            animationData={slug === "creative" ? animCreative : animComms}
          />
        }
        heroImageStatic={
          <HeroImageStatic
            solution
            img={slug === "creative" ? imgStaticCreative : imgStaticComms}
          />
        }
        scrollMarker={true}
        transitionStatus={transitionStatus}
      />

      <main id="main" className="main-content">
        <section id="intro" className="section">
          <div className="container-fluid">
            <div className="row middle-bp3">
              <div className="col-bp1-12 col-bp3-6">
                <Reveal delay={0.25}>
                  <div
                    className="intro"
                    dangerouslySetInnerHTML={{
                      __html: introduction.introduction,
                    }}
                  />
                </Reveal>
              </div>
              <div className="col-bp1-12 col-bp3-6">
                <Reveal delay={0.5}>
                  <ServiceSubNav service={slug} />
                </Reveal>
              </div>
            </div>
          </div>
        </section>

        {imageCarousel?.imageCarousel && (
          <section id="gallery" className="pb-narrow">
            <Gallery images={imageCarousel.imageCarousel} />
          </section>
        )}

        <section
          id="philosophy"
          className="section section--black"
          style={{ position: `relative` }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-bp1-12">
                <Reveal delay={0.25}>
                  <Title>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: solutionsContent.solutionsContentTitle,
                      }}
                    ></span>
                  </Title>
                </Reveal>
              </div>
            </div>
            <div className="row">
              <div className="col-bp1-12 col-bp3-6">
                <Reveal delay={0.5}>
                  <div
                    className="entry"
                    dangerouslySetInnerHTML={{
                      __html: solutionsContent.solutionsContentColumn1,
                    }}
                  />
                </Reveal>
              </div>
              <div className="col-bp1-12 col-bp3-6">
                <Reveal delay={0.75}>
                  <div
                    className="entry"
                    dangerouslySetInnerHTML={{
                      __html: solutionsContent.solutionsContentColumn2,
                    }}
                  />
                </Reveal>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section section--image-group"
          style={{ position: `relative` }}
        >
          <div className="row">
            <div className="col-bp1-12">
              {imageGroup.imageGroup ? (
                <ImageGroup>
                  {imageGroup.imageGroup.map((image, i) => {
                    return (
                      <div key={i} className={`image-group__image`}>
                        <Reveal key={i} delay={i / 4 + 0.25}>
                          <Img
                            fluid={image.localFile.childImageSharp.fluid}
                            alt={image.localFile.altText}
                          />
                        </Reveal>
                      </div>
                    )
                  })}
                </ImageGroup>
              ) : null}
            </div>
          </div>
        </section>

        {featuredWork.featuredWorkItems ? (
          <FeaturedWork featuredWork={featuredWork} />
        ) : null}
      </main>
    </SiteLayout>
  )
}

export default connect(
  state => ({
    breadcrumbs: state.breadcrumbs.breadcrumbs,
  }),
  null
)(Solutions)

export const solutionsQuery = graphql`
  query GET_SOLUTIONS($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          content
          slug
          seo {
            title
            metaDesc
            opengraphTitle
            opengraphDescription
            opengraphImage {
              localFile {
                url
              }
            }
          }
          background {
            backgroundColour
          }
          hero {
            heroSlogan
          }
          introduction {
            introduction
          }
          solutionsContent {
            solutionsContentTitle
            solutionsContentColumn1
            solutionsContentColumn2
          }
          imageGroup {
            imageGroup {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          imageCarousel {
            imageCarousel {
              carouselImage {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          featuredWork {
            featuredWorkItems {
              featuredWorkItem {
                ... on WpProject {
                  id
                  slug
                  uri
                  title
                  projectFields {
                    projectLogoLightMode {
                      altText
                      sourceUrl
                    }
                    projectPreviewImage {
                      altText
                      sourceUrl
                      mimeType
                      localFile {
                        childImageSharp {
                          fluid(
                            maxHeight: 480
                            maxWidth: 680
                            quality: 85
                            cropFocus: CENTER
                          ) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    projectShortDesc
                    projectSubnav {
                      projectSubnavItem {
                        ... on WpPage {
                          id
                          title
                          uri
                          parent {
                            ... on WpPage {
                              slug
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
