import React, { Component } from "react"
import PropTypes from "prop-types"
import lottie from "lottie-web"

class HeroAnimation extends Component {
  componentDidMount() {
    const { options, animationData } = this.props

    this.options = {
      container: this.el,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData,
    }

    this.options = { ...this.options, ...options }

    // Setup our lottie anim with options
    this.anim = lottie.loadAnimation(this.options)
    setTimeout(() => {
      this.anim.play()
    }, 1500)
  }

  componentWillUnmount() {
    this.anim.destroy()
  }

  render() {
    return (
      <div
        className={`hero ${this.props.creative ? `hero--creative` : ``}`}
        ref={c => {
          this.el = c
        }}></div>
    )
  }
}

HeroAnimation.propTypes = {
  eventListeners: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isStopped: PropTypes.bool,
  isPaused: PropTypes.bool,
  goToAndStop: PropTypes.number,
  speed: PropTypes.number,
  segments: PropTypes.arrayOf(PropTypes.number),
  direction: PropTypes.number,
  ariaRole: PropTypes.string,
  ariaLabel: PropTypes.string,
  isClickToPauseDisabled: PropTypes.bool,
  title: PropTypes.string,
  style: PropTypes.string,
}

HeroAnimation.defaultProps = {
  eventListeners: [],
  isStopped: false,
  isPaused: false,
  speed: 1,
  ariaRole: "button",
  ariaLabel: "animation",
  isClickToPauseDisabled: false,
  title: "",
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet",
  },
}

export default HeroAnimation
